import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

export const AudioBackgroundContainer = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
`;

export const AudioContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Glow = (color) => keyframes`
 50% {
  box-shadow: inset 0 0 30px 8px rgba(43, 144, 176, 0.8);
  }
`;

export const AudioQuestionContainer = styled.div`
  width: 300px;
  height: 300px;

  background-color: rgba(1, 18, 41, 0.9);
  box-shadow: 0 0 18px 4px rgba(43, 144, 176, 0.4);
  border-radius: 50%;
  text-align: center;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 50%;
    background-image: radial-gradient(
      circle,
      transparent 68%,
      rgba(43, 144, 176, 1) 70%
    );

    box-shadow: inset 0px 0px 0px rgba(43, 144, 176, 0);
    animation: ${(props) => Glow(props.theme.color)} 5s infinite ease-in-out;
  }
`;

export const LoadingText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 6px;
  opacity: 0.8;
  font-size: 12px;
  color: #2b90b0;
`;

export const SoundBarsContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template-columns: repeat(8, 3px);
  gap: 12px;
  justify-content: center;

  align-items: center;
`;

export const SoundBarsText = styled.div`
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 900;

  background: -webkit-linear-gradient(#ffffff 15%, transparent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0px 0px 10px #2f8ca9;
`;

export const SoundBar = styled.div`
  height: ${(props) => props.height};
  width: 4px;
  /* background: linear-gradient(180deg, #3E859B 0%, rgba(26, 27, 28, 0.43) 100%);
  border-radius: 59px; */

  border-radius: 5px;
  background: linear-gradient(180deg, #3e859b 0%, rgba(26, 27, 28, 0.43) 100%);
  box-shadow: 0px 4px 6px rgba(41, 80, 90, 0.94);
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 16%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;
export const ButtonCmp = styled.div`
  padding: 10px;
  font-weight: bold;

  font-size: 20px;
  transition: 0.5s;

  cursor: pointer;
  text-shadow: 0px 0px 6px #ffffff;

  &:hover {
    color: #5fd5fa;
    text-shadow: 0px 0px 6px #5fd5fa;
  }
`;
