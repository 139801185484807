export const mainTheme = {
  color: {
    White_0: "#ffffff",
    Black_0: "#000000",

    Blue_300: "19, 185, 207",
    Blue_1000: "34, 47, 62",
    Green_700: "42, 61, 56",
  },
};
