import React from 'react';

const ExclamationMarkerIco = ({ width = 2, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 2 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00500011 1.1C-0.00895689 0.960951 0.006384 0.820523 0.0500336 0.68777C0.0936831 0.555017 0.164672 0.432887 0.258422 0.329255C0.352173 0.225623 0.466603 0.142792 0.594332 0.0861015C0.722062 0.0294115 0.860255 0.00012207 1 0.00012207C1.13974 0.00012207 1.27794 0.0294115 1.40567 0.0861015C1.5334 0.142792 1.64783 0.225623 1.74158 0.329255C1.83533 0.432887 1.90632 0.555017 1.94997 0.68777C1.99362 0.820523 2.00896 0.960951 1.995 1.1L1.607 7.45C1.59201 7.60057 1.52159 7.7402 1.40942 7.84177C1.29724 7.94333 1.15132 7.99957 1 7.99957C0.848679 7.99957 0.702759 7.94333 0.590585 7.84177C0.47841 7.7402 0.407989 7.60057 0.393 7.45L0.00500011 1.1ZM0 10C0 9.73478 0.105357 9.48043 0.292893 9.29289C0.48043 9.10535 0.734784 9 1 9C1.26522 9 1.51957 9.10535 1.70711 9.29289C1.89464 9.48043 2 9.73478 2 10C2 10.2652 1.89464 10.5196 1.70711 10.7071C1.51957 10.8946 1.26522 11 1 11C0.734784 11 0.48043 10.8946 0.292893 10.7071C0.105357 10.5196 0 10.2652 0 10Z"
        fill="black"
      />
    </svg>
  );
};

export default ExclamationMarkerIco;
