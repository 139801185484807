import { createRef } from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';
import * as audio from '../audio';

import { popupList, enumPopupList } from '../components/popup/popupList';

const controls = {
  backward: false,
  boost: false,
  jump: false,
  forward: false,
  honk: false,
  left: false,
  right: false,
};

export const angularVelocity = [0, 0.5, 0];
export const position = [-110, 0.75, 220];
export const rotation = [0, Math.PI / 2 + 0.35, 0];
export const maxBoost = 100;

export const vehicleConfig = {
  width: 1.7,
  height: -0.3,
  front: 1.35,
  back: -1.3,
  steer: 0.3,
  force: 1800,
  maxBrake: 65,
  maxSpeed: 88,
};

export const sceneList = {
  INTRO: 'INTRO',
  MAIN_LAND: 'MAIN_LAND',
  CLASSROOM: 'CLASSROOM',
  JURRASICK_WORLD: 'JURRASICK_WORLD',
};

const useStoreImpl = create((set, get) => {
  const actions = {
    onFinish: () => {
      const { finished, start } = get();
      if (start && !finished) {
        set({ finished: Date.now() - start });
      }
    },
    onStart: () => {
      set({ finished: 0, start: Date.now() });
    },
    reset: () => {
      set((state) => {
        state.api.angularVelocity.set(...angularVelocity);
        state.api.position.set(...position);
        state.api.rotation.set(...rotation);
        state.api.velocity.set(0, 0, 0);

        return { ...state, finished: 0, start: 0 };
      });
    },

    onScroll: (values) => {
      // console.log(values);

      const { initialScrollRocket } = get();

      // if (initialScrollRocket < values.top) {
      set({ initialScrollRocket: values.top });
      if (values.top >= 0.99) {
        set({ initialScrollRocketReachedEnd: true });
      }
      // }
    },

    setInitialSound(sound) {
      const { initialLoadingSound } = get();

      if (initialLoadingSound) {
        set({ sound, initialLoadingSound: false, initialLoadingFadeOut: true });

        playAudio(audio.introBackground, 0.2, true);

        //  load sounds
        playAudio(audio.correctSound, 0, false);
      }
    },
    toggleSound(sound = !get().sound) {
      set({ sound });

      if (get().scene === sceneList.JURRASICK_WORLD) {
        playAudio(audio.jungleBackground, 0.2, true);
        pauseAudio(audio.introBackground);
      } else {
        pauseAudio(audio.jungleBackground);
        playAudio(audio.introBackground, 0.2, true);
      }

      //  load sounds

      playAudio(audio.correctSound, 0, false);
    },

    changeSlide: (section, group, slide, show) => {
      const { popupSlides } = get();

      console.log(section, group, slide, show);

      set({
        popupSlides: {
          section: section ? section : popupSlides.section,
          group,
          slide,
          show,
        },
      });
    },

    interaction: () => {
      console.log('enter');

      const { scene, interaction, popupSlides } = get();
      // if (scene === sceneList.MAIN_LAND) {
      if (interaction === 'classRoomZone') {
        console.log('classroom');

        set({ classroomLoading: true, scene: sceneList.CLASSROOM });
      } else {
        if (popupSlides.show) {
          if (popupSlides.section === enumPopupList.LIFE_IN_SCHOOLIO) {
            if (
              popupList.lifeInSchoolio[popupSlides.group].length - 1 >
              popupSlides.slide
            ) {
              set({
                popupSlides: {
                  section: 'lifeInSchoolio',
                  group: popupSlides.group,
                  slide: popupSlides.slide + 1,
                  show: true,
                },
              });
            } else {
              set({
                popupSlides: {
                  section: 'lifeInSchoolio',
                  group: popupSlides.group,
                  slide: popupSlides.slide,
                  show: false,
                },
              });
            }
          } else if (popupSlides.section === enumPopupList.SIDE_MENU) {
            console.log(popupList.sideMenu, popupSlides.group);

            if (
              popupList.sideMenu[popupSlides.group].length - 1 >
              popupSlides.slide
            ) {
              set({
                popupSlides: {
                  section: 'sideMenu',
                  group: popupSlides.group,
                  slide: popupSlides.slide + 1,
                  show: true,
                },
              });
            } else {
              set({
                popupSlides: {
                  section: 'sideMenu',
                  group: popupSlides.group,
                  slide: popupSlides.slide,
                  show: false,
                },
              });
            }
          }
          if (popupSlides.section === enumPopupList.DINO_WORLD) {
            if (
              popupList.dinoworld[popupSlides.group].length - 1 >
              popupSlides.slide
            ) {
              set({
                popupSlides: {
                  section: 'dinoworld',
                  group: popupSlides.group,
                  slide: popupSlides.slide + 1,
                  show: true,
                },
              });
            } else {
              set({
                popupSlides: {
                  section: 'dinoworld',
                  group: popupSlides.group,
                  slide: popupSlides.slide,
                  show: false,
                },
              });
            }
          }
        } else {
          let showGroup = null;

          console.log(interaction);

          if (interaction === 'safari_man') {
            showGroup = 1;
          } else if (interaction === 'coin_1') {
            showGroup = 2;
          } else if (interaction === 'teacher') {
            showGroup = 3;
          } else if (interaction === 'agent') {
            showGroup = 4;
          } else if (interaction === 'egg0') {
            showGroup = 1;
          } else if (interaction === 'egg1') {
            showGroup = 2;
          } else if (interaction === 'egg2') {
            showGroup = 3;
          } else if (interaction === 'quest0') {
            showGroup = 4;
          } else if (interaction === 'quest1') {
            showGroup = 5;
          } else if (interaction === 'quest2') {
            showGroup = 6;
          } else if (interaction === 'quest3') {
            showGroup = 7;
          } else if (interaction === 'excalamation0') {
            showGroup = 13;
          } else if (interaction === 'excalamation1') {
            showGroup = 14;
          } else if (interaction === 'quest4') {
            showGroup = 15;
          } else if (interaction === 'quest5') {
            showGroup = 16;
          } else if (interaction === 'excalamation2') {
            showGroup = 17;
          } else if (interaction === 'quest6') {
            showGroup = 18;
          } else if (interaction === 'quest7') {
            showGroup = 19;
          } else if (interaction === 'excalamation3') {
            showGroup = 20;
          } else if (interaction === 'quest8') {
            showGroup = 21;
          } else if (interaction === 'quest9') {
            showGroup = 22;
          } else if (interaction === 'quest10') {
            showGroup = 23;
          } else if (interaction === 'AllTasksCompleted') {
            showGroup = 24;
          }

          if (showGroup) {
            if (scene === sceneList.MAIN_LAND) {
              set({
                popupSlides: {
                  section: 'lifeInSchoolio',
                  group: showGroup,
                  slide: 0,
                  show: true,
                },
              });
            } else if (scene === sceneList.JURRASICK_WORLD) {
              set({
                popupSlides: {
                  section: 'dinoworld',
                  group: showGroup,
                  slide: 0,
                  show: true,
                },
              });
            }
          }
        }
      }
      // }
    },
  };

  return {
    actions,
    api: null,
    controls,
    dpr: 1,
    editor: false,
    finished: 0,
    get,
    set,
    help: false,
    leaderboard: false,
    map: true,
    ready: false,
    session: null,
    sound: false,
    start: 0,
    vehicleConfig,
    envMapSpace: null,
    initialLoading: true,
    initialLoadingSound: true,
    initialLoadingFadeOut: false,
    initialScrollRocket: 0,
    rocketRef: createRef(),
    initialScrollRocketReachedEnd: false,
    ejectLoading: false,
    classroomLoading: false,
    dinosourParkLoading: false,
    dinosaurLoadedOnce: false,
    scene: sceneList.INTRO,
    totalLoadedObjects: 0,

    renderSceneRef: createRef(),
    interaction: null,
    interactionDescription: '',

    popupSlides: { section: 'lifeInSchoolio', group: 0, slide: 0, show: false },
    dinoTasksQuest: {
      quest0: false,
      quest1: false,
      quest2: false,
      quest3: false,
      quest4: false,
      quest5: false,
      quest6: false,
      quest7: false,
      quest8: false,
      quest9: false,
      quest10: false,
    },
    dinoTasksEgg: { egg0: false, egg1: false, egg2: false },
    dinoTasksInforamation: {
      information0: false,
      information1: false,
      information2: false,
      information3: false,
    },

    dinoTasksExcalamationMark: {
      excalamation0: false,
      excalamation1: false,
      excalamation2: false,
      excalamation3: false,
    },
  };
});

// Make the store shallow compare by default
const useStore = (sel) => useStoreImpl(sel, shallow);
Object.assign(useStore, useStoreImpl);

const { getState, setState } = useStoreImpl;

function playAudio(audio, volume = 1, loop = false) {
  if (useStore.getState().sound) {
    audio.currentTime = 0;
    audio.volume = volume;
    audio.loop = loop;
    audio.play();
  } else audio.pause();
}

function pauseAudio(audio) {
  if (useStore.getState().sound) {
    audio.pause();
  }
}

export { getState, setState, useStore, audio, playAudio, pauseAudio };
