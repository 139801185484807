import React, { useEffect, useRef } from 'react';

import { useStore } from '../../../store/store';

import { useSpring } from 'react-spring';

import {
  AudioBackgroundContainer,
  AudioContainer,
  AudioQuestionContainer,
  SoundBarsContainer,
  SoundBar,
  SoundBarsText,
  ButtonsContainer,
  ButtonCmp,
} from './audio.css';

const Audio = () => {
  const fadeOutAudio = useRef(false);

  const [initialLoading, setInitialSound] = useStore((state) => [
    state.initialLoading,
    state.actions.setInitialSound,
  ]);

  const [{ opacity }, setSpring] = useSpring(() => ({
    opacity: 0,
    config: {
      mass: 5,
      tension: 400,
      friction: 90,
      precision: 0.001,
      clamp: true,
    },
  }));

  useEffect(() => {
    if (!initialLoading) {
      setSpring({
        opacity: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading]);

  const handleClickButton = (sound) => {
    if (!fadeOutAudio.current) {
      setInitialSound(sound);
    }
    fadeOutAudio.current = true;
  };

  return (
    <>
      {!initialLoading ? (
        <AudioBackgroundContainer style={{ opacity: opacity }}>
          <AudioContainer>
            <AudioQuestionContainer />
            <SoundBarsContainer>
              <SoundBar height={'40px'} />
              <SoundBar height={'55px'} />
              <SoundBar height={'75px'} />
              <SoundBar height={'60px'} />
              <SoundBar height={'55px'} />
              <SoundBar height={'63px'} />
              <SoundBar height={'55px'} />
              <SoundBar height={'40px'} />
              <SoundBarsText>sound</SoundBarsText>
            </SoundBarsContainer>
            <ButtonsContainer>
              <ButtonCmp onClick={() => handleClickButton(true)}>on</ButtonCmp>
              <ButtonCmp onClick={() => handleClickButton(false)}>
                off
              </ButtonCmp>
            </ButtonsContainer>
          </AudioContainer>
        </AudioBackgroundContainer>
      ) : null}
    </>
  );
};

export default Audio;
