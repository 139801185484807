import React from 'react';

const Close = ({ width = 8, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.228901 0.228909C0.371992 0.0858606 0.566039 0.00550079 0.76837 0.00550079C0.970701 0.00550079 1.16475 0.0858606 1.30784 0.228909L4.00595 2.92702L6.70406 0.228909C6.84797 0.0899146 7.04072 0.0130045 7.24078 0.014743C7.44085 0.0164816 7.63223 0.0967297 7.7737 0.238204C7.91518 0.379677 7.99543 0.571057 7.99716 0.771124C7.9989 0.971191 7.92199 1.16394 7.783 1.30785L5.08489 4.00596L7.783 6.70407C7.92199 6.84798 7.9989 7.04072 7.99716 7.24079C7.99543 7.44086 7.91518 7.63224 7.7737 7.77371C7.63223 7.91519 7.44085 7.99543 7.24078 7.99717C7.04072 7.99891 6.84797 7.922 6.70406 7.78301L4.00595 5.0849L1.30784 7.78301C1.16393 7.922 0.971183 7.99891 0.771117 7.99717C0.57105 7.99543 0.37967 7.91519 0.238196 7.77371C0.0967221 7.63224 0.0164739 7.44086 0.0147354 7.24079C0.0129969 7.04072 0.089907 6.84798 0.228901 6.70407L2.92701 4.00596L0.228901 1.30785C0.085853 1.16476 0.00549316 0.970709 0.00549316 0.768378C0.00549316 0.566047 0.085853 0.372 0.228901 0.228909V0.228909Z"
        fill="white"
      />
    </svg>
  );
};

export default Close;
