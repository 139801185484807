import React from 'react';

const ContactUs = ({ width = 19, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.4375 1.1875H17.8125V3.5625H15.4375V1.1875Z" fill="#CEDBDC" />
      <path d="M15.4375 4.75H17.8125V7.125H15.4375V4.75Z" fill="#CEDBDC" />
      <path d="M11.875 1.1875H14.25V3.5625H11.875V1.1875Z" fill="#CEDBDC" />
      <path d="M11.875 4.75H14.25V7.125H11.875V4.75Z" fill="#CEDBDC" />
      <path
        d="M14.8437 17.8125H14.7428C3.07562 17.1416 1.41906 7.29719 1.1875 4.29281C1.15131 3.82182 1.30369 3.35574 1.61112 2.99709C1.91855 2.63844 2.35585 2.41659 2.82684 2.38034C2.87395 2.37678 2.92125 2.375 2.96875 2.375H6.09781C6.33564 2.37477 6.56806 2.44596 6.76497 2.57935C6.96187 2.71273 7.11419 2.90217 7.20219 3.12313L8.10468 5.34375C8.19158 5.55961 8.21315 5.79623 8.1667 6.02424C8.12025 6.25225 8.00784 6.46159 7.84343 6.62625L6.57875 7.90281C6.77549 9.02579 7.31285 10.0611 8.11795 10.8683C8.92304 11.6755 9.95692 12.2156 11.0794 12.4153L12.3678 11.1387C12.5349 10.9762 12.7463 10.8664 12.9754 10.8231C13.2045 10.7798 13.4413 10.8049 13.6562 10.8953L15.8947 11.7919C16.1123 11.8826 16.298 12.0362 16.428 12.2328C16.5581 12.4295 16.6267 12.6605 16.625 12.8962V16.0312C16.625 16.5037 16.4373 16.9567 16.1033 17.2908C15.7692 17.6248 15.3162 17.8125 14.8437 17.8125V17.8125ZM2.96875 3.5625C2.89078 3.56227 2.81352 3.57739 2.74139 3.60701C2.66927 3.63664 2.60368 3.68018 2.54838 3.73515C2.49308 3.79011 2.44915 3.85544 2.41909 3.92739C2.38904 3.99933 2.37345 4.0765 2.37322 4.15447C2.37322 4.17109 2.37322 4.18713 2.375 4.20375C2.64812 7.71875 4.39969 16.0312 14.8081 16.625C14.9653 16.6344 15.1197 16.581 15.2375 16.4766C15.3553 16.3722 15.4268 16.2252 15.4363 16.0681L15.4375 16.0312V12.8962L13.1991 11.9997L11.495 13.6919L11.21 13.6562C6.04437 13.0091 5.34375 7.84344 5.34375 7.79L5.30812 7.505L6.99437 5.80094L6.10375 3.5625H2.96875Z"
        fill="#CEDBDC"
      />
    </svg>
  );
};

export default ContactUs;
