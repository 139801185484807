import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

import loaderBackground from '../../images/loaders/initial_loader.png';

export const Container = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  text-transform: uppercase;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  color: white;

  background-color: #000000;
  background: url('${loaderBackground}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 6vh;
  top: 6vh;
`;

export const InfoContainer = styled.div`
  position: absolute;
  left: 8vh;
  bottom: 8vh;
`;

export const Title = styled.div`
  font-size: 40px;
  text-shadow: 0px 0px 6px #ffffff;
  margin-bottom: 10px;
  text-align: left;
  letter-spacing: 3px;
`;

export const SubTitle = styled.span`
  /* font-size: 50px; */
  color: #2ebce9;
  text-shadow: 0px 0px 6px #2ebce9;
  font-weight: bold;
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

export const SubTitleImage = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 10px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  box-shadow: 0px 4px 15px #3e859b;
`;
