import React from 'react';

const EggtMarkerIco = ({ width = 6, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94241 3.70667C5.60185 1.30667 4.04891 0 3 0C1.95109 0 0.398151 1.30667 0.0575947 3.70667C-0.269339 6.10667 0.806818 8 3 8C5.19318 8 6.26934 6.10667 5.94241 3.70667V3.70667Z"
        fill="#FFC294"
      />
      <path
        opacity="0.33"
        d="M5.81739 3.71613C5.55652 1.88387 4.57826 0.709679 3.67826 0.296776C4.2913 0.903227 4.83913 1.85807 5.00869 3.14839C5.33478 5.47097 4.30435 7.30323 2.1913 7.30323C1.73478 7.30323 1.33043 7.2129 0.991302 7.05807C1.47391 7.5742 2.15217 7.87097 3 7.87097C5.1 7.87097 6.13043 6.03871 5.81739 3.71613"
        fill="#D3976E"
      />
    </svg>
  );
};

export default EggtMarkerIco;
