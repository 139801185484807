import React from 'react';

const InfoMarkerIco = ({ width = 2, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 2 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.944 2.71652L0.111964 2.96899L0.0463626 3.30328L0.406369 3.37629C0.641574 3.43787 0.687975 3.53112 0.636774 3.78887L0.0463626 6.83967C-0.10884 7.62877 0.130364 8 0.692775 8C1.12878 8 1.63519 7.77832 1.8648 7.47394L1.9352 7.10798C1.7752 7.26281 1.54159 7.32439 1.38639 7.32439C1.16638 7.32439 1.08638 7.15461 1.14318 6.85551L1.944 2.71652ZM2 0.879701C2 1.11301 1.91571 1.33677 1.76568 1.50174C1.61565 1.66672 1.41216 1.7594 1.19998 1.7594C0.987807 1.7594 0.784321 1.66672 0.634289 1.50174C0.484257 1.33677 0.399969 1.11301 0.399969 0.879701C0.399969 0.64639 0.484257 0.422634 0.634289 0.257658C0.784321 0.0926825 0.987807 0 1.19998 0C1.41216 0 1.61565 0.0926825 1.76568 0.257658C1.91571 0.422634 2 0.64639 2 0.879701Z"
        fill="white"
      />
    </svg>
  );
};

export default InfoMarkerIco;
