import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

import loaderBackground from '../../images/loaders/jurassic_loader.jpg';

export const Container = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  text-transform: uppercase;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  color: white;

  background-color: #000000;
  background: url('${loaderBackground}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LogoContainer = styled.div`
  position: absolute;
`;

export const HintContainer = styled.div`
  position: absolute;

  bottom: 5%;
`;
