import React, { memo, useEffect, useState } from 'react';

import { useProgress } from '@react-three/drei';

import { useStore, getState } from '../../store/store';

import loaderImg_1 from '../../images/loaders/loader_pic_1.png';
import loaderImg_2 from '../../images/loaders/loader_pic_2.png';
import loaderImg_3 from '../../images/loaders/loader_pic_3.png';
import loaderImg_4 from '../../images/loaders/loader_pic_4.png';

import {
  Container,
  LogoContainer,
  InfoContainer,
  Title,
  SubTitle,
  ImagesContainer,
  SubTitleImage,
} from './initialLoader.css';
import { useSpring } from 'react-spring';

import Loader from './components/loader';

import { LogoLoaderInitial } from '../../icons';
import Audio from './components/audio';

const InitialLoader = ({ suspenceComponent }) => {
  const [showLoader, setShowLoader] = useState(true);

  const [initialLoading, initialLoadingFadeOut, set] = useStore((state) => [
    state.initialLoading,
    state.initialLoadingFadeOut,
    state.set,
  ]);

  const { active, progress, errors, item, loaded, total } = useProgress();
  // console.log(progress, item, active, loaded, total);
  const totalAmountItem = 47; // fixed ammout of items loaded

  const [{ opacity }, setSpring] = useSpring(() => ({
    opacity: 1,
    config: {
      mass: 5,
      tension: 400,
      friction: 90,
      precision: 0.001,
      clamp: true,
    },
  }));

  useEffect(() => {
    if (initialLoading) {
      if (
        loaded >= getState().totalLoadedObjects - totalAmountItem &&
        progress >= 100 &&
        initialLoading
      ) {
        set({ totalLoadedObjects: loaded, initialLoading: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, initialLoading, progress]);

  useEffect(() => {
    if (initialLoadingFadeOut) {
      setSpring({
        opacity: 0,
        onRest: () => {
          setShowLoader(false);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoadingFadeOut]);

  return (
    <>
      {showLoader ? (
        <Container style={{ opacity: opacity }}>
          <LogoContainer>
            <LogoLoaderInitial width={300} height={154} />
          </LogoContainer>
          <Loader />
          <InfoContainer>
            <Title>
              Bored of <SubTitle>School?</SubTitle>
            </Title>
          </InfoContainer>
          <Audio />
        </Container>
      ) : null}
    </>
  );
};

export default memo(InitialLoader);
