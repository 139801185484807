import React from 'react';

const SoundOff = ({ width = 14, height = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30833 1.2137C5.50278 1.28945 5.6 1.43149 5.6 1.63981V9.36658C5.6 9.5749 5.50278 9.71694 5.30833 9.79269C5.23056 9.81163 5.17222 9.8211 5.13333 9.8211C5.01667 9.8211 4.90486 9.77849 4.79792 9.69327L2.37708 7.32126H0.466667C0.330556 7.32126 0.21875 7.27865 0.13125 7.19343C0.04375 7.10821 0 6.99931 0 6.86674V4.13965C0 4.00708 0.04375 3.89819 0.13125 3.81296C0.21875 3.72774 0.330556 3.68513 0.466667 3.68513H2.37708L4.79792 1.31313C4.95347 1.17109 5.12361 1.13795 5.30833 1.2137ZM11.3896 11C11.691 11 11.934 10.8864 12.1188 10.6591C13.3729 9.15353 14 7.43489 14 5.5032C14 3.5715 13.3729 1.85286 12.1188 0.347279C11.9632 0.148428 11.7542 0.0347989 11.4917 0.00639163C11.2292 -0.0220156 11.0007 0.044268 10.8063 0.205242C10.6021 0.366217 10.4878 0.57217 10.4635 0.8231C10.4392 1.07403 10.5097 1.29892 10.675 1.49777C11.6472 2.66247 12.1333 3.99761 12.1333 5.5032C12.1333 7.00878 11.6472 8.34392 10.675 9.50862C10.5097 9.70747 10.4392 9.93236 10.4635 10.1833C10.4878 10.4342 10.6021 10.6354 10.8063 10.7869C10.9813 10.929 11.1757 11 11.3896 11ZM8.3125 8.89786C8.575 8.89786 8.80347 8.80317 8.99792 8.61379C9.84375 7.73317 10.2667 6.6963 10.2667 5.5032C10.2667 4.31009 9.84375 3.27323 8.99792 2.3926C8.82292 2.21269 8.60417 2.118 8.34167 2.10853C8.07917 2.09906 7.85556 2.17955 7.67083 2.34999C7.48611 2.52043 7.38889 2.73112 7.37917 2.98205C7.36944 3.23298 7.45694 3.45314 7.64167 3.64252C8.14722 4.18226 8.4 4.80248 8.4 5.5032C8.4 6.20391 8.14722 6.82413 7.64167 7.36387C7.45694 7.55325 7.36944 7.77341 7.37917 8.02434C7.38889 8.27527 7.48611 8.48596 7.67083 8.6564C7.86528 8.81738 8.07917 8.89786 8.3125 8.89786Z"
        fill="white"
      />
    </svg>
  );
};

export default SoundOff;
