import React from 'react';

const BlockchainAndTech = ({ width = 19, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.857 9.93283V12.9076C17.857 14.1967 14.7493 15.8824 10.9158 15.8824C7.08227 15.8824 3.97461 14.1967 3.97461 12.9076V10.4286"
        stroke="#CEDBDC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.2666 10.6854C5.12433 11.8238 7.77685 12.8917 10.9162 12.8917C14.7498 12.8917 17.8574 11.2992 17.8574 9.93082C17.8574 9.16233 16.8787 8.32047 15.3427 7.72948"
        stroke="#CEDBDC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8824 4.97482V7.94961C14.8824 9.23869 11.7747 10.9244 7.94118 10.9244C4.10766 10.9244 1 9.23869 1 7.94961V4.97482"
        stroke="#CEDBDC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94118 7.93371C11.7747 7.93371 14.8824 6.34121 14.8824 4.97281C14.8824 3.60341 11.7747 2 7.94118 2C4.10766 2 1 3.60341 1 4.97281C1 6.34121 4.10766 7.93371 7.94118 7.93371Z"
        stroke="#CEDBDC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BlockchainAndTech;
