import React from 'react';

const SoundOn = ({ width = 14, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30833 1.21369C5.50278 1.28944 5.6 1.43148 5.6 1.6398V9.36657C5.6 9.57489 5.50278 9.71693 5.30833 9.79268C5.23056 9.81162 5.17222 9.82109 5.13333 9.82109C5.01667 9.82109 4.90486 9.77848 4.79792 9.69325L2.37708 7.32125H0.466667C0.330556 7.32125 0.21875 7.27864 0.13125 7.19342C0.04375 7.10819 0 6.9993 0 6.86673V4.13963C0 4.00707 0.04375 3.89817 0.13125 3.81295C0.21875 3.72773 0.330556 3.68512 0.466667 3.68512H2.37708L4.79792 1.31311C4.95347 1.17108 5.12361 1.13793 5.30833 1.21369Z"
        fill="white"
      />
      <path
        d="M7.19568 2.19568C7.32102 2.07039 7.49099 2 7.66821 2C7.84543 2 8.0154 2.07039 8.14074 2.19568L10.504 4.55898L12.8673 2.19568C12.9934 2.07394 13.1622 2.00657 13.3375 2.0081C13.5127 2.00962 13.6803 2.07991 13.8042 2.20383C13.9282 2.32774 13.9985 2.49538 14 2.67062C14.0015 2.84586 13.9341 3.01468 13.8124 3.14074L11.4491 5.50404L13.8124 7.86733C13.9341 7.99339 14.0015 8.16221 14 8.33745C13.9985 8.51269 13.9282 8.68033 13.8042 8.80424C13.6803 8.92816 13.5127 8.99845 13.3375 8.99997C13.1622 9.0015 12.9934 8.93413 12.8673 8.81239L10.504 6.44909L8.14074 8.81239C8.01468 8.93413 7.84586 9.0015 7.67062 8.99997C7.49538 8.99845 7.32774 8.92816 7.20383 8.80424C7.07991 8.68033 7.00962 8.51269 7.0081 8.33745C7.00657 8.16221 7.07394 7.99339 7.19568 7.86733L9.55898 5.50404L7.19568 3.14074C7.07039 3.0154 7 2.84543 7 2.66821C7 2.49099 7.07039 2.32102 7.19568 2.19568Z"
        fill="white"
      />
    </svg>
  );
};

export default SoundOn;
