import React, { useEffect } from 'react';

import { Container, LogoContainer, HintContainer } from './jurassicLoader.css';

import { useStore, sceneList, getState } from '../../store/store';

import { useSpring } from 'react-spring';

import LoadingIndicator from '../loadingIndicator';

import { useProgress } from '@react-three/drei';

import { LogoLoaderInitial } from '../../icons';

const EjectLoader = () => {
  const [dinosourParkLoading, set] = useStore((state) => [
    state.dinosourParkLoading,
    state.set,
  ]);

  const { actions, active, progress, errors, item, loaded, total } =
    useProgress();
  console.log(progress, item, active, loaded, total);
  const totalAmountItem = 1; // fixed ammout of items loaded

  const [{ opacity }, setSpring] = useSpring(() => ({
    opacity: 0,
    config: {
      mass: 5,
      tension: 400,
      friction: 400,
      precision: 0.0001,
      clamp: true,
    },
  }));

  useEffect(() => {
    if (dinosourParkLoading) {
      console.log('loader 3');
      set({
        totalLoadedObjects: loaded,
      });
      setSpring({
        opacity: 1,
        onRest: () => {
          set({
            scene: sceneList.JURRASICK_WORLD,
          });
        },
      });
    }
  }, [dinosourParkLoading]);

  useEffect(() => {
    if (dinosourParkLoading) {
      console.log(getState().totalLoadedObjects + totalAmountItem);

      if (
        loaded >= getState().totalLoadedObjects + totalAmountItem &&
        progress >= 100 &&
        dinosourParkLoading
      ) {
        set({
          totalLoadedObjects: loaded,
          popupSlides: {
            section: 'jurrasickworld',
            group: 0,
            slide: 0,
            show: false,
          },
        });
        setTimeout(() => {
          setSpring({
            opacity: 0,
            config: {
              mass: 5,
              tension: 400,
              friction: 90,
              precision: 0.001,
              clamp: true,
            },
            onRest: () => {
              set({
                dinosourParkLoading: false,
                dinosaurLoadedOnce: true,
              });
            },
          });
        }, 1400);
      }
    }
  }, [loaded, dinosourParkLoading, progress]);

  return (
    <>
      {dinosourParkLoading ? (
        <Container style={{ opacity: opacity }}>
          <LoadingIndicator />

          <LogoContainer>
            <LogoLoaderInitial width={150} height={77} />
          </LogoContainer>

          <HintContainer>
            In case you are lost, click the 'help' button
          </HintContainer>
        </Container>
      ) : null}
    </>
  );
};

export default EjectLoader;
