import React from 'react';

const PartnersAndBrand = ({ width = 19, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.265 3.58957C13.9109 3.58957 13.5647 3.69457 13.2703 3.8913C12.9759 4.08803 12.7464 4.36765 12.6109 4.6948C12.4754 5.02194 12.4399 5.38193 12.509 5.72922C12.5781 6.07652 12.7486 6.39554 12.999 6.64592C13.2494 6.89631 13.5684 7.0668 13.9157 7.1359C14.263 7.205 14.623 7.1695 14.9501 7.034C15.2773 6.89852 15.5569 6.66904 15.7536 6.37462C15.9503 6.08019 16.0554 5.73404 16.0554 5.37994C16.0542 4.90547 15.8651 4.45079 15.5296 4.11529C15.1941 3.77979 14.7394 3.59077 14.265 3.58957Z"
        stroke="#CEDBDC"
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.77996 2.57977C7.28422 2.57977 6.79961 2.72678 6.38741 3.0022C5.97522 3.27762 5.65395 3.66908 5.46424 4.12709C5.27452 4.5851 5.22489 5.08907 5.3216 5.57529C5.41832 6.06151 5.65704 6.50813 6.00758 6.85867C6.35812 7.2092 6.80474 7.4479 7.29096 7.5447C7.77718 7.6414 8.28116 7.5917 8.73916 7.402C9.1972 7.2123 9.5886 6.89104 9.8641 6.47884C10.1395 6.06665 10.2865 5.58204 10.2865 5.08629C10.2851 4.42195 10.0206 3.7852 9.5508 3.31544C9.0811 2.84567 8.44431 2.58115 7.77996 2.57977Z"
        stroke="#CEDBDC"
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M13.4336 11.4497L13.8288 14.6359H14.4287V13.5H15.3818V14.6359H18.4812V11.5441C18.4562 11.0741 18.3165 10.6173 18.0745 10.2137C17.8324 9.81 17.4953 9.4717 17.0925 9.2283C16.0927 8.5781 14.9209 8.2425 13.7285 8.2648C13.09 8.25302 12.4575 8.34386 11.8541 8.53069L13.3086 9.229L11.5 10L10.5599 9.1076C10.4943 9.14676 10.4294 9.18723 10.3652 9.229C10.2923 9.2798 10.1543 9.3852 10.1543 9.3852L10.9863 9.9119C10.952 9.9323 10.9184 9.954 10.8854 9.977C10.8288 10.0167 10.722 10.0981 10.722 10.0981L11.7552 10.393L13.4336 11.4497Z"
        fill="#CEDBDC"
      />
      <path
        d="M7.80894 9.3187C9.3548 9.285 10.8829 9.6524 12.2445 10.3851C13.3513 11.0362 13.9502 11.8767 13.9502 12.7289V15.9131H1.66699V12.7308C1.66699 11.876 2.26791 11.0349 3.37273 10.3871C4.7343 9.6536 6.26273 9.2855 7.80894 9.3187Z"
        stroke="#CEDBDC"
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default PartnersAndBrand;
