export const enumPopupList = {
  LIFE_IN_SCHOOLIO: 'lifeInSchoolio',
  SIDE_MENU: 'sideMenu',
  DINO_WORLD: 'dinoworld',
};

export const popupList = {
  lifeInSchoolio: [
    ['1', '2'],
    ['3', '4'],
    ['6', '7'],
    ['8', '9'],
    ['10', '11', '12'],
  ],
  sideMenu: [['1'], ['2'], ['3'], ['4'], ['5']],
  dinoworld: [
    ['1'], //none
    ['2'], //egg0
    ['3'], //egg1
    ['4'], //egg2
    ['5'], //quest0
    ['6'], //quest1
    ['7'], //quest2
    ['8'], //quest3
    ['9'], //info0
    ['10'], //info1
    ['11'], //info2
    ['12'], //info3
    ['13'], //excalamation0
    ['14'],
    ['15'],
    ['16'],
    ['17'],
    ['18'],
    ['19'],
    ['20'],
    ['21'],
    ['22'],
    ['23'],
    ['24'],
    ['25'],
  ],
};
