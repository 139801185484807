import React from 'react';

import { LoadingContainer, Loader, LoadingText } from './loader.css';

import { useStore } from '../../../store/store';

const LoadingElement = () => {
  const [initialLoading] = useStore((state) => [state.initialLoading]);

  return (
    <>
      {initialLoading ? (
        <LoadingContainer>
          <Loader />
          <LoadingText>Loading</LoadingText>
        </LoadingContainer>
      ) : null}
    </>
  );
};

export default LoadingElement;
