import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

const Glow = (color) => keyframes`
 50% {
  box-shadow: inset 0 0 30px 8px rgba(43, 144, 176, 0.8);
  }
`;

const Spin = () => keyframes`
  50% {
    opacity: 0.2;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Loader = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;

  background-color: rgba(1, 18, 41, 0.9);
  box-shadow: 0 0 18px 4px rgba(43, 144, 176, 0.4);
  border-radius: 50%;
  text-align: center;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 50%;
    background-image: radial-gradient(
      circle,
      transparent 68%,
      rgba(43, 144, 176, 1) 70%
    );

    box-shadow: inset 0px 0px 0px rgba(43, 144, 176, 0);
    animation: ${(props) => Glow(props.theme.color)} 5s infinite ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    transform-origin: 50% 50%;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    z-index: 999;
    border: solid 2px rgb(${(props) => props.theme.color.Blue_300});
    border-radius: 50%;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: ${Spin} 1.5s infinite linear;
  }
`;
