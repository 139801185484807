import React from 'react';

const ContactUs = ({ width = 81, height = 31 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M40.5 5.8125C40.7569 5.8125 41.0033 5.91456 41.185 6.09624C41.3667 6.27792 41.4688 6.52432 41.4688 6.78125V10.6562C41.4688 10.9132 41.3667 11.1596 41.185 11.3413C41.0033 11.5229 40.7569 11.625 40.5 11.625C40.2431 11.625 39.9967 11.5229 39.815 11.3413C39.6333 11.1596 39.5312 10.9132 39.5312 10.6562V6.78125C39.5312 6.52432 39.6333 6.27792 39.815 6.09624C39.9967 5.91456 40.2431 5.8125 40.5 5.8125V5.8125ZM48.25 21.3125C48.25 23.3679 47.4335 25.3392 45.9801 26.7926C44.5267 28.246 42.5554 29.0625 40.5 29.0625C38.4446 29.0625 36.4733 28.246 35.0199 26.7926C33.5665 25.3392 32.75 23.3679 32.75 21.3125V9.6875C32.75 7.63207 33.5665 5.66083 35.0199 4.20742C36.4733 2.75402 38.4446 1.9375 40.5 1.9375C42.5554 1.9375 44.5267 2.75402 45.9801 4.20742C47.4335 5.66083 48.25 7.63207 48.25 9.6875V21.3125ZM40.5 0C37.9307 0 35.4667 1.02064 33.6499 2.8374C31.8331 4.65416 30.8125 7.11822 30.8125 9.6875V21.3125C30.8125 23.8818 31.8331 26.3458 33.6499 28.1626C35.4667 29.9794 37.9307 31 40.5 31C43.0693 31 45.5333 29.9794 47.3501 28.1626C49.1669 26.3458 50.1875 23.8818 50.1875 21.3125V9.6875C50.1875 7.11822 49.1669 4.65416 47.3501 2.8374C45.5333 1.02064 43.0693 0 40.5 0V0Z"
          fill="white"
        />
        <path
          d="M33.5003 12.5L39.0004 12.4999L39.0004 3C35.0004 4 33.0004 7 33.5003 12.5Z"
          fill="white"
        />
        <path
          d="M81.375 16.5L68.25 11L68.25 15.5833L57 15.5833L57 17.4167L68.25 17.4167L68.25 22L81.375 16.5Z"
          fill="white"
        />
        <path
          d="M0 16.5L13.125 22V17.4167H24.375V15.5833H13.125V11L0 16.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ContactUs;
