import React from 'react';

const AboutUs = ({ width = 19, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.59549 1.03726H2.20945C1.57217 1.03726 1.05556 1.55387 1.05556 2.19114V5.26868C1.05556 5.90596 1.57217 6.42257 2.20945 6.42257H7.59549C8.23278 6.42257 8.74939 5.90596 8.74939 5.26868V2.19114C8.74939 1.55387 8.23278 1.03726 7.59549 1.03726Z"
        fill="white"
      />
      <path
        d="M7.59554 1.40507H2.2095C1.77543 1.40507 1.42355 1.75694 1.42355 2.19101V5.26854C1.42355 5.7026 1.77543 6.05448 2.2095 6.05448H7.59554C8.02961 6.05448 8.38148 5.7026 8.38148 5.26854V2.19101C8.38148 1.75694 8.02961 1.40507 7.59554 1.40507Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M16.742 1.03726H11.3559C10.7186 1.03726 10.202 1.55387 10.202 2.19115V9.88497C10.202 10.5223 10.7186 11.0389 11.3559 11.0389H16.742C17.3792 11.0389 17.8958 10.5223 17.8958 9.88497V2.19115C17.8958 1.55387 17.3792 1.03726 16.742 1.03726Z"
        fill="#0073B4"
      />
      <path
        d="M7.59549 7.96119H2.20945C1.57217 7.96119 1.05556 8.4778 1.05556 9.11507V16.8089C1.05556 17.4462 1.57217 17.9628 2.20945 17.9628H7.59549C8.23278 17.9628 8.74939 17.4462 8.74939 16.8089V9.11507C8.74939 8.4778 8.23278 7.96119 7.59549 7.96119Z"
        fill="#0073B4"
      />
      <path
        d="M16.742 12.5774H11.3559C10.7186 12.5774 10.202 13.094 10.202 13.7313V16.8088C10.202 17.4461 10.7186 17.9627 11.3559 17.9627H16.742C17.3792 17.9627 17.8958 17.4461 17.8958 16.8088V13.7313C17.8958 13.094 17.3792 12.5774 16.742 12.5774Z"
        fill="white"
      />
      <path
        d="M16.742 12.9453H11.3559C10.9218 12.9453 10.57 13.2971 10.57 13.7313V16.8088C10.57 17.2428 10.9218 17.5947 11.3559 17.5947H16.742C17.176 17.5947 17.5279 17.2428 17.5279 16.8088V13.7313C17.5279 13.2971 17.176 12.9453 16.742 12.9453Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default AboutUs;
