import React from 'react';

const LeftArrowIco = ({ width = 10, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.46565 10.7287L2.25191 6.19058C2.1603 6.09193 2.09557 5.98505 2.05771 5.86995C2.01924 5.75486 2 5.63154 2 5.5C2 5.36846 2.01924 5.24514 2.05771 5.13004C2.09557 5.01495 2.1603 4.90807 2.25191 4.80942L6.46565 0.2713C6.63359 0.0904331 6.84733 0 7.10687 0C7.36641 0 7.58015 0.0904331 7.74809 0.2713C7.91603 0.452167 8 0.682362 8 0.961883C8 1.2414 7.91603 1.4716 7.74809 1.65247L4.17557 5.5L7.74809 9.34753C7.91603 9.5284 8 9.75859 8 10.0381C8 10.3176 7.91603 10.5478 7.74809 10.7287C7.58015 10.9096 7.36641 11 7.10687 11C6.84733 11 6.63359 10.9096 6.46565 10.7287Z"
        fill="black"
      />
    </svg>
  );
};

export default LeftArrowIco;
