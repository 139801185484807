import React from 'react';

const Rocket_1 = ({ width = 200, height = 432 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 200 432"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.57 211.174C139.298 208.033 145.805 203.675 152.184 197.844C163.727 187.323 164.736 171.85 165.424 161.282C165.485 160.346 165.544 159.448 165.606 158.596C166.382 148.366 165.24 134.02 159.486 115.884C152.102 92.9386 143.003 82.2205 137.74 77.6556C136.149 76.27 133.701 77.4927 133.864 79.571C135.415 104.31 126.071 110.626 120.767 96.4438C118.646 90.7792 117.422 80.9978 117.422 69.0969C117.422 49.249 111.669 28.8713 98.98 12.2838C95.6753 7.96372 91.7996 3.92896 87.3928 0.505505C85.8014 -0.757915 83.4758 0.505505 83.5983 2.54328C84.5773 15.9926 83.6801 54.5065 49.8567 100.519C16.8086 145.473 25.1727 165.973 35.2911 184.883C36.9302 188.027 44.628 200.538 59.3155 209.583C42.4723 204.937 0.150866 193.542 0.0685671 193.66C-0.322045 193.986 1.04509 206.283 1.92397 210.382C4.65825 223.237 10.9081 235.86 19.7944 246.247C25.0189 252.302 35.2236 260.873 41.6199 264.505C42.4011 264.924 42.4011 265.251 41.4734 268.93C35.8584 291.894 36.3466 314.949 42.9382 338.565C51.4828 369.027 71.3554 402.237 96.9406 428.879L99.9184 432L104.069 427.668C116.764 414.394 127.506 400.048 138.198 382.115C145.23 370.331 152.798 352.957 156.46 340.195C163.491 315.835 164.175 293.291 158.559 269.443C157.632 265.53 157.583 264.971 158.266 264.552C158.657 264.272 161.001 262.689 163.491 261.058C180.726 249.508 193.568 230.783 197.963 210.615C198.988 206.05 200.355 194.033 199.915 193.613C199.818 193.521 138.248 210.196 134.39 211.36C134.133 211.442 133.882 211.403 133.57 211.174ZM78.142 336.05C80.9249 341.08 86.9306 345.738 92.7902 347.415C101.725 349.93 111.002 347.6 117.545 341.219C122.476 336.422 124.918 330.832 124.918 324.451C124.918 315.788 119.107 307.171 110.661 303.212C94.4012 295.62 75.1147 307.171 75.1147 324.545C75.1147 328.876 75.9937 332.23 78.142 336.05Z"
          fill="url(#paint0_linear_15_2)"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_15_2" x1="100%" y1="100%">
          <stop offset="0%" stopColor="white" stopOpacity=".5">
            <animate
              attributeName="stop-color"
              values="white;yellow;red;purple;white"
              dur="14s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="white" stopOpacity="0.22">
            <animate
              attributeName="stop-color"
              values="white;orange;purple;red;white"
              dur="14s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="offset"
              values=".95;.80;.60;.40;.20;0;.20;.40;.60;.80;.95"
              dur="14s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Rocket_1;
