import React from 'react';

const QuestMarkerIco = ({ width = 6, height = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.15658 0C1.90941 0 1.10162 0.510984 0.467413 1.42228C0.352366 1.58759 0.387835 1.81425 0.548319 1.93594L1.22235 2.44702C1.38443 2.56991 1.61504 2.54117 1.74193 2.3822C2.13332 1.89187 2.42365 1.60956 3.03501 1.60956C3.51569 1.60956 4.11026 1.91892 4.11026 2.38505C4.11026 2.73742 3.81937 2.91839 3.34474 3.18448C2.79126 3.4948 2.05882 3.88098 2.05882 4.84706V5C2.05882 5.20711 2.22671 5.375 2.43382 5.375H3.56618C3.77329 5.375 3.94118 5.20711 3.94118 5V4.9098C3.94118 4.24011 5.89849 4.21222 5.89849 2.4C5.89851 1.03525 4.48285 0 3.15658 0ZM3.00001 5.8353C2.40319 5.8353 1.91765 6.32084 1.91765 6.91766C1.91765 7.51445 2.40319 8 3.00001 8C3.59682 8 4.08237 7.51445 4.08237 6.91764C4.08237 6.32083 3.59682 5.8353 3.00001 5.8353Z"
        fill="black"
      />
    </svg>
  );
};

export default QuestMarkerIco;
