import React, { memo, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { mainTheme } from '../constants/theme';

import InitialLoader from '../components/initialLoader';
import DinoWorldLoader from '../components/jurrasicLoader';

const HomePage = React.lazy(() => import('./home'));
const DinoWorldPage = React.lazy(() => import('./dinoWorld'));
const ErrorPage = React.lazy(() => import('./error'));

const App = () => {
  // console.log(process.env.REACT_APP_API_URL);

  return (
    <ThemeProvider theme={mainTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<InitialLoader suspenceComponent={true} />}>
                <HomePage />
              </Suspense>
            }
          />
          <Route
            path="/dino-world"
            element={
              <Suspense fallback={<DinoWorldLoader suspenceComponent={true} />}>
                <DinoWorldPage />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={null}>
                <ErrorPage />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default memo(App);
