import React from 'react';

const RightArrowIco = ({ width = 10, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_201_4)">
        <path
          d="M3.53435 0.271302L7.74809 4.80942C7.83969 4.90807 7.90443 5.01495 7.94229 5.13005C7.98076 5.24514 8 5.36846 8 5.5C8 5.63154 7.98076 5.75486 7.94229 5.86996C7.90443 5.98505 7.83969 6.09193 7.74809 6.19058L3.53435 10.7287C3.36641 10.9096 3.15267 11 2.89313 11C2.63359 11 2.41985 10.9096 2.25191 10.7287C2.08397 10.5478 2 10.3176 2 10.0381C2 9.7586 2.08397 9.5284 2.25191 9.34753L5.82443 5.5L2.25191 1.65247C2.08397 1.4716 2 1.24141 2 0.961884C2 0.682362 2.08397 0.452169 2.25191 0.271302C2.41985 0.0904353 2.63359 2.65248e-07 2.89313 2.42558e-07C3.15267 2.19868e-07 3.36641 0.0904352 3.53435 0.271302Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_4">
          <rect width="10" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightArrowIco;
