import React from 'react';

const LifeInSchoolio = ({ width = 19, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 1L1 3V4.33333L9.5 2.33333L18 4.33333V3L9.5 1ZM5.57692 5C3.46696 5 1.40865 5.87533 1.40865 5.87533L1 6.062V16.3333H8.37604C8.60358 16.732 9.01681 17 9.5 17C9.98254 17 10.3964 16.732 10.624 16.3333H18V6.06267L17.5913 5.87533C17.5913 5.87533 15.533 5 13.4231 5C11.5152 5 9.84 5.65333 9.5 5.792C9.16 5.65333 7.48485 5 5.57692 5ZM5.57692 6.33333C6.84146 6.33333 8.19231 6.75 8.84615 6.97933V14.396C8.11842 14.1513 6.92319 13.8127 5.57692 13.8127C4.20254 13.8127 3.08381 14.1353 2.30769 14.396V6.97933C2.81115 6.79333 4.13846 6.33333 5.57692 6.33333ZM13.4231 6.33333C14.8615 6.33333 16.1888 6.794 16.6923 6.97933V14.396C15.9155 14.136 14.7975 13.8127 13.4231 13.8127C12.0768 13.8127 10.8816 14.1513 10.1538 14.396V6.97933C10.8077 6.75 12.1585 6.33333 13.4231 6.33333Z"
        fill="#CEDBDC"
      />
    </svg>
  );
};

export default LifeInSchoolio;
